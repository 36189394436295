import React, { useEffect, useState } from "react";
import {
  Text,
  useDisclosure,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalCloseButton,
  ModalBody,
  ModalFooter,
  Button,
  Modal,
  FormControl,
  FormLabel,
  Input,
  Box,
} from "@chakra-ui/core";
// @ts-ignore
import HubspotForm from "react-hubspot-form";

interface ModalProps {
  isOpen: boolean;
  onOpen: () => void;
  onClose: () => void;
}
export const ApplyModal: React.FC<ModalProps> = ({
  onClose,
  isOpen,
  onOpen,
}) => {
  //const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      {/* <Button onClick={onOpen}>Join the Network</Button> */}

      <Modal blockScrollOnMount={false} isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalCloseButton />

          <ModalBody>
            <Box>
              <HubspotForm
                portalId="7536017"
                formId="7f04ff5d-9707-486b-b759-8d7662e97512"
                onSubmit={() => console.log("Submit!")}
                onReady={() => console.log("Form ready!")}
                loading={<div>Loading...</div>}
              />
            </Box>
          </ModalBody>
        </ModalContent>
      </Modal>
    </>
  );
};
