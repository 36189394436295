import React, { useState } from "react";
import { HomePageLayoutWrapper } from "../components/HomePageLayoutWrapper";
import {
  Flex,
  Box,
  SimpleGrid,
  Text,
  Divider,
  Stack,
  ButtonGroup,
  Button,
  Icon,
  useDisclosure,
  Input,
} from "@chakra-ui/core";

import { PageWidthWrapper } from "../components/PageWidthWrapper";
import { ApplyModal } from "../components/modals/ApplyModal";
import { graphql, PageProps } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import { SubscribeModal } from "../components/modals/SubscribeModal";

import Image from "gatsby-image";
import { CaseStudy } from "../types";
import { OPACITY } from "vega-lite/build/src/channel";
interface DataProps {
  first: {
    childImageSharp: {
      fluid: any;
    };
  };
  second: {
    childImageSharp: {
      fluid: any;
    };
  };
}

export const query = graphql`
  query {
    first: file(relativePath: { eq: "applynowpage_version2.jpg" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
    second: file(relativePath: { eq: "AdobeStock_142802670.jpeg" }) {
      childImageSharp {
        fluid(maxWidth: 1100) {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`;
const ApplyNow: React.FC<PageProps<DataProps>> = ({ data }) => {
  const { onOpen, onClose, isOpen } = useDisclosure();
  const [subscribeModalVisable, setSubscribeModalVisable] = useState<boolean>(
    false
  );
  const showSubscribe = () => {
    setSubscribeModalVisable(true);
  };
  const hideSubscribe = () => {
    setSubscribeModalVisable(false);
  };
  return (
    <HomePageLayoutWrapper
      showFooter={true}
      variant="secondary"
      pageTitle={"Apply Now"}
    >
      <PageWidthWrapper paddingLeft="0">
        <Box
          position="relative"
          h={["390px", "590px", "750px"]}
          w={["350px", "750px", "1330px"]}
        >
          <Flex
            bottom="0"
            left="0"
            position="absolute"
            mt="60px"
            backgroundColor="#E9E9E9"
            h={["390px", "590px", "600px"]}
            w={["280px", "480px", "1100px"]}
          ></Flex>
          <Flex
            position="absolute"
            right="0"
            top={["40px", "40px", 0]}
            backgroundSize="cover"
            backgroundColor="blackPrimary"
            //backgroundImage={`url("https://firebasestorage.googleapis.com/v0/b/core-innovation-network-public-assets/o/addyourbusiness.jpg?alt=media")`}
            h={["280px", "480px", "670px"]}
            w={["280px", "480px", "780px"]}
            align="center"
            opacity={0.5}
          >
            <BackgroundImage
              fluid={data.first.childImageSharp.fluid}
              style={{
                position: "absolute",
                height: "100%",
                width: "100%",
                display: "flex",
                alignItems: "center",
              }}
            />
          </Flex>
          <Flex
            direction="column"
            position="absolute"
            w={["270px", "570px", "922px"]}
            right={["60px", "60px", "123px"]}
          >
            <Text
              color="blackPrimary"
              fontSize={["28px", "50px", "68px"]}
              mt="75px"
            >
              Connecting South Australia’s Energy & Resources innovation
              ecosystem.
            </Text>
            <Text
              color="blackPrimary"
              fontSize={["12px", "20px"]}
              width="335px"
            >
              Join the community with one simple form
            </Text>
            <ButtonGroup spacing={0} mt="50px">
              <Button
                borderRadius="0px"
                color="whitePrimary"
                backgroundColor="bluePrimary"
                h="48px"
                onClick={onOpen}
                textTransform="uppercase"
                fontSize={["12px", "14px"]}
              >
                Add My Business
              </Button>
              <Icon
                color="whitePrimary"
                size="48px"
                padding="12px"
                name="chevron-right"
                aria-label="apply"
                backgroundColor="blueTertiary"
              />
            </ButtonGroup>
          </Flex>
        </Box>
      </PageWidthWrapper>

      <Stack spacing="70px">
        <PageWidthWrapper
          justify="center"
          mt="140px"
          h="100%"
          paddingLeft={["20px", "20px", "257px"]}
          paddingRight={["20px", "20px", "257px"]}
        >
          <Flex direction="column" w="100%">
            <Stack spacing="140px">
              <Stack spacing="20px">
                {/* <Text color="blackPrimary" fontWeight="bold">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  tristique vel eget sit elementum, nunc nam facilisi. Enim
                  ultrices non porttitor id sed nulla.
                </Text> */}
                <SimpleGrid columns={[1, 1, 2]} spacing="65px">
                  <Text color="blackPrimary">
                    In South Australia we have a local energy and resources
                    industry well-placed to lead the world in innovation, and
                    where it is widely accepted that true innovation will drive
                    the next wave of productivity gains and financial growth.
                    Our goal at CORE is to foster an environment where
                    innovation thrives and where innovators are actively
                    supported to develop the technologies of tomorrow.
                  </Text>
                  <Text color="blackPrimary">
                    The CORE Innovation Ecosystem Map has been created to
                    provide information and connection to the South Australian
                    energy and resources community. Identifying the key players
                    in any sector is a key step to facilitating collaboration.
                    We have openly sourced the first round of data from the
                    community. But as with any ecosystem, it’s a living,
                    evolving resource and we encourage you to contribute to the
                    Map by adding your venture if it’s not been identified
                    already.
                  </Text>
                </SimpleGrid>
              </Stack>

              <Divider />
              <Stack spacing="30px">
                <Text color="bluePrimary" fontWeight="bold" fontSize="12px">
                  Quote
                </Text>
                <Text color="blackPrimary" fontWeight="bold" fontSize="26px">
                  “CORE’s mission is to connect industry, academics, government
                  and innovators to collaborate with intent on challenges across
                  the energy and resources sector”
                </Text>
                <Text color="blackPrimary" fontWeight="bold" fontSize="16px">
                  Renee Hakendorf, CORE Innovation Hub
                </Text>
              </Stack>

              <Divider />
              {/* <Flex justify="space-between">
                <Text w="330px" color="blackPrimary">
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit. Urna
                  tristique vel eget sit elementum, nunc nam facilisi. Enim
                  ultrices non porttitor id sed nulla. Eget mauris urna, tellus,
                  erat nunc interdum iaculis ornare lorem. Erat commodo
                  scelerisque sollicitudin ultrices fermentum in ut pellentesque
                  orci.
                </Text>
                <Image
                  size="320px"
                  src="https://firebasestorage.googleapis.com/v0/b/core-innovation-network-public-assets/o/Rectangle%206(2).png?alt=media"
                />
              </Flex> */}
            </Stack>
          </Flex>
        </PageWidthWrapper>

        <PageWidthWrapper
          paddingLeft={["10px", "10px", "138px"]}
          paddingRight={["10px", "10px", "138px"]}
        >
          <Flex
            h={["100%", "100%", "570px"]}
            backgroundColor="whitePrimary"
            justify="space-between"
            align="center"
            w="100%"
            direction={["column", "column", "row"]}
          >
            <SimpleGrid
              padding={["8px"]}
              columns={2}
              spacingX={["15px", "20px"]}
              margin={["0px", "120px"]}
              spacingY="80px"
            >
              <Flex direction="column">
                <Icon name="worldIcon" size="27px" mb="14px" />
                <Text
                  fontWeight="bold"
                  color="blackPrimary"
                  fontSize="14px"
                  maxW={["166px", "200px"]}
                >
                  Access a directory of innovators
                </Text>
              </Flex>
              <Flex direction="column">
                <Icon name="groupIcon" size="27px" mb="14px" />
                <Text
                  fontWeight="bold"
                  color="blackPrimary"
                  fontSize="14px"
                  maxW={["166px", "200px"]}
                >
                  Connect with local partners
                </Text>
              </Flex>

              <Flex direction="column">
                <Icon name="flagIcon" size="27px" mb="14px" />
                <Text
                  fontWeight="bold"
                  color="blackPrimary"
                  fontSize="14px"
                  maxW={["166px", "200px"]}
                >
                  Promote your areas of expertise using technology tags
                </Text>
              </Flex>
              <Flex direction="column">
                <Icon name="worldIcon" size="27px" mb="14px" />
                <Text
                  fontWeight="bold"
                  color="blackPrimary"
                  fontSize="14px"
                  maxW={["166px", "200px"]}
                >
                  Identify clusters of capability
                </Text>
              </Flex>
            </SimpleGrid>
            <Box h="100%" width={["100%", "100%", "600px"]}>
              <Image
                fluid={data.second.childImageSharp.fluid}
                style={{ height: "100%", objectFit: "cover" }}
              />
            </Box>
          </Flex>
        </PageWidthWrapper>
        <PageWidthWrapper
          paddingLeft={["10px", "10px", "138px"]}
          paddingRight={["10px", "10px", "138px"]}
        >
          <Flex
            direction={["column", "column", "row"]}
            minH="360px"
            backgroundColor="blackPrimary"
            paddingX={["32px", "120px"]}
            paddingY={["68px", "68px", 0]}
            align={["left", "center"]}
            justify="space-between"
          >
            <Flex direction="column" mb={["30px", 0]}>
              <Text
                fontSize={["48px", "68px"]}
                color="whitePrimary"
                width={["100%", "360px"]}
              >
                Stay Connected.
              </Text>
              <Text color="whitePrimary" fontSize="14px">
                We promise not to spam.
              </Text>
            </Flex>

            <Button
              mt={[0, "30px", 0]}
              w="195px"
              color="whitePrimary"
              variant="ghost"
              borderColor="whitePrimary"
              border="1px"
              onClick={() => {
                showSubscribe();
              }}
            >
              Subscribe Now
            </Button>
          </Flex>
        </PageWidthWrapper>
        <Divider />
      </Stack>
      <ApplyModal isOpen={isOpen} onClose={onClose} onOpen={onOpen} />
      <SubscribeModal
        onClose={hideSubscribe}
        onOpen={showSubscribe}
        isOpen={subscribeModalVisable}
      />
    </HomePageLayoutWrapper>
  );
};

export default ApplyNow;
